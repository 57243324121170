const flatListToHierarchical = (
    data = [],
    { idKey = "id", parentKey = "parentId", childrenKey = "children" } = {}
) => {
    const tree = []
    const childrenOf = {}
        data.forEach(item => {
        const newItem = { ...item }
        const { [idKey]: id, [parentKey]: parentId = 0 } = newItem
        childrenOf[id] = childrenOf[id] || []
        newItem[childrenKey] = childrenOf[id]
        parentId
            ? (childrenOf[parentId] = childrenOf[parentId] || []).push(newItem)
            : tree.push(newItem)
    })
    return tree
}

const operatorFontColors = {
    Telia: "#9012de",
    Saunalahti: "#0822aa",
    Elisa: "#0822aa",
    DNA: "#fe017d",
    Moi: "#ff1601"
}

const getClientId = () => {
    if (window[window.GoogleAnalyticsObject]) {
        let _ga = window[window.GoogleAnalyticsObject]
        return _ga.getAll()[0].get('clientId')
    }

    return "private"
}

const getURLWithClientID = (url, clientId) => {
    if (!clientId)
        return url

    if (url.includes("adservice")) {
        if (!url.includes("sub="))
            return `${url}&sub={client_id:"${clientId}"}`
    } else if (url.includes("&as=")) {
        if (!url.includes("epi="))
            return `${url.replace("?", `?epi=cid...${clientId}&`)}`
    }

    return url
}

/**
 * https://stackoverflow.com/a/5782563
 */
const generateSlug = text => {
    text = text.replace(/^\s+|\s+$/g, '') // trim
    text = text.toLowerCase()
  
    // remove accents, swap ñ for n, etc
    const from = "ÁÄÂÀÃÅČÇĆĎÉĚËÈÊẼĔȆĞÍÌÎÏİŇÑÓÖÒÔÕØŘŔŠŞŤÚŮÜÙÛÝŸŽáäâàãåčçćďéěëèêẽĕȇğíìîïıňñóöòôõøðřŕšşťúůüùûýÿžþÞĐđßÆa·/_,:;"
    const to   = "AAAAAACCCDEEEEEEEEGIIIIINNOOOOOORRSSTUUUUUYYZaaaaaacccdeeeeeeeegiiiiinnooooooorrsstuuuuuyyzbBDdBAa------"

    for (let i = 0, l = from.length; i < l; i++) {
      text = text.replace(new RegExp(from.charAt(i), 'g'), to.charAt(i))
    }
  
    text = text.replace(/[^a-z0-9 -]/g, '') // remove invalid chars
             .replace(/\s+/g, '-') // collapse whitespace and replace by -
             .replace(/-+/g, '-') // collapse dashes
  
    return text
}

module.exports = {
    flatListToHierarchical,
    operatorFontColors,
    getClientId,
    getURLWithClientID,
    generateSlug,
}
