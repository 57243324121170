import { graphql, Link, StaticQuery } from "gatsby"
import React, { useState } from "react"
import { StaticImage } from "gatsby-plugin-image"

import menuToggle from "../content/assets/img/menutoggle.svg"
import close from "../content/assets/img/close.svg"
import { flatListToHierarchical } from "./_helpers"

const NavBar = () => {
    const [mobileMenuActive, setMobileMenuActive] = useState(false)
    const [openSubMenu, setOpenSubMenu] = useState("")

    const toggleSubMenu = (e, topLevelMenuItem) => {
        e.preventDefault()
        
        if (openSubMenu === topLevelMenuItem.path) {
            setOpenSubMenu("")
        } else {
            setOpenSubMenu(topLevelMenuItem.path)
        }
    }

    return (
        <nav>
            <div className="container">
                <div className="logoContainer">
                    <Link to="/">
                        <StaticImage width={350} src={`../content/assets/img/puhelinliittymat-logo.png`} alt={`Puhelinliittymat.fi logo`} />
                    </Link>
                </div>
                <div className={`navItemsContainer ${mobileMenuActive ? 'active' : ''}`}>
                    <StaticQuery 
                        query={
                            graphql`
                                {
                                    allWpMenuItem(filter: { locations: { eq: GATSBY_HEADER_MENU } }) {
                                        nodes {
                                            id
                                            label
                                            title
                                            path
                                            parentId
                                        }
                                    }
                                }
                            `
                        }
                        render={data => {
                            const structuredHeaderMenuData = flatListToHierarchical(data.allWpMenuItem.nodes)

                            return structuredHeaderMenuData.map((topLevelMenuItem, index) => {
                                return (
                                    <div className="topLevelMenuItemContainer" key={`menuItemIndex-${index}`}>
                                        <Link   to={topLevelMenuItem.path}
                                                activeClassName="active"
                                                className="topLevelMenuItem"
                                        >
                                            { topLevelMenuItem.label }
                                            { topLevelMenuItem.children.length > 0 &&
                                                <button aria-describedby="Avaa alavalikko" onClick={e => toggleSubMenu(e, topLevelMenuItem)}  className="toggleSubMenuButton" type="button">
                                                    { openSubMenu !== topLevelMenuItem.path && "▼" }
                                                    { openSubMenu === topLevelMenuItem.path && "▲"}
                                                </button>
                                            }
                                        </Link>
                                        <div className={`subMenu ${openSubMenu === topLevelMenuItem.path ? 'visible' : ''}`} key={`menuItemIndex-${index}-subMenu`}>
                                            { topLevelMenuItem.children.map((child, _index) => (
                                                <Link   to={child.path}
                                                        key={`menuItemIndex-${index}-child-${_index}`}
                                                        className="subMenuItem"
                                                        activeClassName="active"
                                                >
                                                    <span>{ child.label }</span>
                                                </Link>
                                            )) }
                                        </div>
                                    </div>
                                )
                            })
                        }}
                    />
                </div>
                <div className="navToggleContainer">
                    { mobileMenuActive === false && (
                        <button type="button" aria-describedby="Avaa mobiilivalikko" onClick={() => {
                            (document.querySelector(".topLevelMenuItemContainer")).focus()
                            setMobileMenuActive(!mobileMenuActive)
                        }}>
                            <img src={menuToggle} alt="Ikoni - Avaa mobiilivalikko" />
                        </button>
                    ) }
                    { mobileMenuActive === true && (
                        <button type="button" aria-describedby="Sulje mobiilivalikko" onClick={() => setMobileMenuActive(!mobileMenuActive)}>
                            <img src={close} alt="Ikoni - Sulje mobiilivalikko" />
                        </button>
                    ) }
                </div>
            </div>
        </nav>
    )   
}

export default NavBar
