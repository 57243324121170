import React, { useState, useEffect } from "react"
import { Helmet } from "react-helmet"

import NavBar from "./NavBar"
import Footer from "./Footer"

import c from "../_config"
import CookieNotice from "./CookieNotice"

const Layout = ({ children, currentURL = "", modifiedGmt = "", parentPages = [], currentTitle = "", uri = "" }) => {
    const [hideCookieNotice, setHideCookieNotice] = useState("1")

    useEffect(() => {
        if (typeof window === 'undefined')
            return

        setHideCookieNotice(window.localStorage.getItem("hideCookieNotice"))
        
        return () => null
    }, [])

    const hideCookieNoticeAndStoreToLocalStorage = () => {
        setHideCookieNotice("1")

        if (typeof window !== 'undefined') 
            window.localStorage.setItem("hideCookieNotice", "1")
    }

    return (
        <div className="siteContainer">
            <Helmet lang={c.siteLanguageCode}>
                <title>Puhelinliittymat.fi</title>
                { c.noindex === true && <meta name="robots" content="noindex, nofollow" /> }
            </Helmet>
            <a className="skip-to-content-link" href="#main">Siirry sivun pääsisältöön</a>
            <a className="skip-to-content-link" href="#footer">Siirry footeriin</a>
            <NavBar currentURL={currentURL} />
            { children }
            <Footer modifiedGmt={modifiedGmt} parentPages={parentPages} currentTitle={currentTitle} uri={uri} />
            { hideCookieNotice !== "1" && (
                <CookieNotice onButtonClick={hideCookieNoticeAndStoreToLocalStorage} /> 
            )}
        </div>
    )
}

export default Layout
