import { Link } from "gatsby"
import React from "react"

const CookieNotice = ({ onButtonClick }) => (
    <div className="cookieNotice">
        <div className="container">
            <div className="cookieNoticeContent innerPadding">
                <span className="cookieNoticeContent__text">Tämä sivusto käyttää evästeitä taatakseen parhaan mahdollisen kokemuksen. Lue lisätiedot <Link to="/tietoa-sivustolla-kaytetyista-evasteista/">evästeselosteesta</Link>.</span>
                <button onClick={onButtonClick} type="button">OK</button>
            </div>
        </div>
    </div>
)

export default CookieNotice