import React from "react"
import { graphql, StaticQuery } from "gatsby"

const Footer = ({ modifiedGmt = "2021-07-01", parentPages = [], currentTitle = "Puhelinliittymat.fi", uri = "" }) => {
    const d = modifiedGmt ? new Date(modifiedGmt) : new Date("2021-07-01T04:20:07.626Z")
    const computerReadableDatetime = d.toISOString()
    const localizedDate = `${d.getDate()}.${d.getMonth()+1}.${d.getFullYear()}`

    return (
        <footer className="footer" id="footer">
            <div ariaLabel="Breadcrumb" className="lastModified" style={{ backgroundColor: "rgba(0,0,0,.1)", padding: "8px 0" }}>
                <div className="container" style={{ paddingLeft: "12px", paddingRight: "12px" }}>
                    { uri !== "/" && (
                        <>
                            <a href="/">Etusivu</a>&nbsp;»
                            { parentPages.map(parentPage => (
                                <span>&nbsp;<a href={parentPage.url}>{ parentPage.title }</a>&nbsp;»</span>
                            ))}
                            &nbsp;{ currentTitle }
                        </>
                    )}
                    <div style={{ marginTop: "12px" }}>Päivitetty viimeksi: <time dateTime={computerReadableDatetime}>{ localizedDate }</time></div>
                </div>
            </div>
            
            <div className="container">
                <div className="footerContent">
                <StaticQuery 
                    query={
                        graphql`
                            {
                                allWpFooterBlock {
                                    nodes {
                                        slug
                                        content
                                    }
                                }
                            }
                        `
                    }
                    render={footerBlocks => {
                        const sortedData = orderFooterBlocksBySlug(footerBlocks.allWpFooterBlock.nodes)

                        return (
                            sortedData.map((item, i) => (
                                <div className="footerItemContainer" key={"footer-item-" + i}>
                                    <div dangerouslySetInnerHTML={{__html: item.content}} />
                                </div>
                            ))
                        )
                    }}
                />
                </div>
            </div>
        </footer>
    )
}

const orderFooterBlocksBySlug = footerBlocks => {
    let data = [...footerBlocks]

    data.sort((a, b) => {
        let sortingValueA = parseInt(a.slug.replace("block-", "")) // "block-1" => 1
        let sortingValueB = parseInt(b.slug.replace("block-", ""))

        if (sortingValueA > sortingValueB)
            return 1 
        
        if (sortingValueB > sortingValueA)
            return -1

        return 0
    })
    
    return data
}

export default Footer
